/*
 * The main styles.scss file is mainly used to make all global styles
 * available in a single file.
 *
 * Actual styles (classes, variables, e.t.c) should be placed into the correct sub folder
 * (i.e. '../globals' or '../variables')
 */

/* Include all global styles */
@use "globals";
@use "../app/core/ui/ui/ui.component.global-styles";

/* Include reset-files that alter the display of predefined components such as material components or native html components */
@use "resets";

/* Overwrite some MDC (Material) styles to work with variables */
@use "./mdc_overwrites/mdc_overwrites";

/* Include the styles for directives here to make them available as global style
   (currently, it is not possible to use styles in a directive) */
@use "../app/core/common-components/border-highlight/border-highlight.directive";
@use "../app/core/alerts/alert-style-classes";
