@use "../variables/colors";

/* display disabled form values in black for better readability as we disable forms by default */
.mat-mdc-input-element:disabled,
.mat-mdc-select-disabled .mat-mdc-select-value,
.mat-mdc-checkbox-disabled label {
  color: colors.$text !important;
  -webkit-text-fill-color: black;
  opacity: 1;
}

.mdc-text-field--filled {
  background: transparent !important;
}
