@use "variables/ndb-light-theme" as theme;
@use "@angular/material" as mat;

/*
 * This file holds the required overwrites for styles broken due to css variables.
 * more background: https://github.com/angular/components/issues/25981#issuecomment-1515332869
 */

/* Text color for fab buttons otherwise defaults to black */
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mat-fab-foreground-color: #{mat.m2-get-contrast-color-from-palette(
      theme.$accent,
      A200
    )} !important;
}

/* Text color for buttons with accent color otherwise defaults to black */
.mat-mdc-raised-button:not(:disabled)[color="accent"] {
  --mdc-protected-button-label-text-color: #{mat.m2-get-contrast-color-from-palette(
      theme.$accent,
      A200
    )} !important
;
}

/* Progress bar background color should have opacity to indicate progress */
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  opacity: 25%;
}

/* Color for enabled checkbox otherwise defaults to black */
.mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__checkmark {
  --mdc-checkbox-selected-checkmark-color: #{mat.m2-get-contrast-color-from-palette(
      theme.$accent,
      A200
    )} !important
;
}

/* Color for selected date range in date picker defaults to white */
.mat-calendar-body-in-range::before {
  --mat-datepicker-calendar-date-in-range-state-background-color: #{color-mix(
      in srgb,
      mat.m2-get-color-from-palette(theme.$primary, 500),
      transparent 80%
    )} !important;
}
